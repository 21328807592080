body {
  background-color: #0d0221;
  font-family: sans-serif;
}

@media screen and (max-width: 1000px) {
  .profile-name {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .profile-card {
    padding: 20px 15px;
  }

  .code-card {
    padding: 20px 15px;
  }

  .profile-name {
    font-size: 16px;
  }
}

.title {
  color: white;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.p {
  color: #ffffff;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: center;
}

.btn {
  height: 45px;
  width: 100px;
}

.profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 30px;
  margin: 0 30px;
}

.profile-icon {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.profile-name {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
}

.profile-position {
  font-size: 14px;
  color: #ffffff;
}

.navbar {
  padding: 30px;
}

.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.data {
  cursor: pointer;
  transition: 0.3s;
  padding: 10px;
}

.data:hover {
  transform: scale(1.1);
  text-shadow: 2px 2px 8px #ccc;
}
